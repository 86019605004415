import axios from '@/plugins/http.js';
class CoursewareManagement {
  getFileList (params) { // 获取文件列表
    return axios.get(`/file-list?${params}`);
  }
  addFile (params) { // 添加文件
    return axios.post(`/file`, params);
  }
  updateFile (params) { // 修改文件
    return axios.put(`/file`, params);
  }
  delFile (id) { // 删除文件
    return axios.delete(`/file/${id}`);
  }
  addFileList (params) { // 批量添加文件
    return axios.post(`/file-list`,params);
  }
  delFileList (params) { // 批量删除文件
    return axios.delete(`/file-list?fileIds=${params}`);
  }
  fileDetail (id) { // 文件详情
    return axios.get(`/file/${id}`);
  }
  getStreamList(courseId) {
    return axios.get(`course/${courseId}/stream-info-list`);
  }
  turnVideo(params){
    return axios.post(`stream-info/transfer/file-list`,params);
  }
}
export default new CoursewareManagement();
