<template>
  <div class="hh_classify_wrap">
    <div class="hh_sub common_inner">
      <p class="gl_sub_t">{{ label.medicalLabel }}:</p>
      <div class="hh_classify">
        <span
          class="hh_classify_item"
          :class="{ hh_classify_active: allStatusOne === 1 }"
          @click="clickheadMenu('oneAll')"
          >全部</span
        >
        <span
          class="hh_classify_item"
          :class="[act == index ? 'hh_classify_active' : '']"
          v-for="(item, index) in reheadListOne"
          :key="item.id"
          @click="clickheadMenu('oneItem', item, index)"
          >{{ item.medicalName }}</span
        >
        <div v-show="isTwo" class="hh_twoSub_wrap">
          <div class="hh_classify hh_pad">
            <!-- <el-collapse accordion>
                 <el-collapse-item> -->
            <!-- <template slot="title"> -->
            <div @click="stopProp">
              <span
                class="hh_item"
                :class="{ hh_classify_active: allStatusTwo === 1 }"
                @click="clickheadMenu('secAll')"
                >全部</span
              >
              <span
                class="hh_item"
                :class="active == index ? 'yh_classify_active' : ''"
                v-for="(item, index) in reheadListTwo"
                :key="index"
                @click.stop="clickhead('oneItem', item, index)"
                >{{ item.medicalName }}
              </span>
            </div>
            <!-- </template> -->
            <div>
              <span
                class="hh_item"
                :class="subTypeInd == index ? 'yh_classify_actived' : ''"
                v-for="(item, index) in reheadListThr"
                :key="item.id"
                @click="clickheadChil('oneItem', item, index)"
                >{{ item.medicalName }}</span
              >
            </div>
            <!-- </el-collapse-item>
               </el-collapse> -->
          </div>
        </div>
      </div>
    </div>
    <hr class="hh_line common_inner" />
    <div v-show="!searchType" class="hh_sub common_inner">
      <p class="gl_sub_t">{{ label.courseLabel }}:</p>
      <div class="hh_classify">
        <span
          class="hh_classify_item"
          :class="{ hh_classify_active: subType === 1 }"
          @click="allSubType('oneAll')"
          >全部</span
        >
        <span
          class="hh_classify_item"
          :class="subTypeInd == index ? 'yh_classify_subTypeInd' : ''"
          v-for="(item, index) in subTypeLsit"
          :key="item.id"
          @click="goSubType('oneItem', item, index)"
          >{{ item.dictLabel }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/apis/studentsEndAPI/liveListAPI";
import dictionatyAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
export default {
  name: "category",
  props: [
    "searchType",
    "subjectInd",
    "subjectChilInd",
    "chilrenList",
    "levelsubJectId",
  ],
  data() {
    return {
      act: -1,
      active: -1,
      actived: -1,
      subTypeInd: -1,
      allStatusOne: 1,
      allStatusTwo: 1,
      subType: 1, //分类
      clickoneStatus: "",
      reheadListOne: [],
      reheadListTwo: [],
      reheadListThr: [],
      secondDatas: [],
      subTypeLsit: [],
      isTwo: false,
      label: {},
      towLevelAllId: "", //二级全部id，一级父id
    };
  },
  created() {},
  mounted() {
    this.label = this.$store.state.label;
    let list = this.$route.params.chilrenList;
    this.getMedicalList();
    this.getDiction();
    this.$EventBus.$on("fromLive", (val) => {
      if (val) {
        this.$store.commit("PARENTID", null);
        this.$store.commit("PARENTChiLD", null);
        this.$store.commit("SETALL", 1);
        this.$emit("subjectId", "", "all");
      }
    });
    this.$EventBus.$on("fromDibble", (val) => {
      if (val) {
        this.$store.commit("PARENTID", null);
        this.$store.commit("PARENTChiLD", null);
        this.$store.commit("SETALL", 1);
        this.$emit("subjectId", "", "all");
      }
    });
    this.act = this.$store.state.student.parentId;
    this.active = this.$store.state.student.parentChild;
    if (this.act != -1) this.allStatusOne = -1; //act ! = -1 说明有值让索引显示，为null显示全部
    if (this.act == null) this.allStatusOne = 1;
    if (this.active != -1) this.allStatusTwo = -1;
    if (this.active == null) this.allStatusTwo = -1;
    if (this.$store.state.student.all != null) this.allStatusTwo = 1;
    // debugger
    let secondData = JSON.parse(sessionStorage.getItem("secondList"));
    if (secondData) {
      this.isTwo = true;
      this.reheadListTwo = secondData;
    }
    if (list) {
      if (JSON.parse(list.children.length > 0)) {
        this.isTwo = true;
        this.reheadListTwo = this.$route.params.chilrenList.children;
      }
    }
  },
  watch: {
    subjectInd(val) {
      if (val && this.subjectInd) {
        this.act = this.subjectInd;
        this.allStatusOne = -1;
      }
    },
    subjectChilInd(val) {
      if (val) {
        this.active = this.subjectChilInd;
        this.allStatusTwo = -1;
      }
    },
    chilrenList: {
      handler(val) {
        if (val) {
          this.isTwo = true;
          this.reheadListTwo = val.children;
        }
      },
    },
    "$store.state.student.parentChild"() {
      this.act = this.$store.state.student.parentId;
      this.active = this.$store.state.student.parentChild;
      this.allStatusOne = -1;
    },
    "$store.state.student.all"() {
      this.allStatusTwo === 1;
    },
    deep: true,
  },
  methods: {
    async getDiction() {
      let dictType = "course_type";
      let data = await dictionatyAPI.getDicData(dictType);
      if (data && !data.status) {
        this.subTypeLsit = data;
      }
    },
    async getMedicalList() {
      let status = "1";
      let data = await API.getMedicalList(status);
      this.reheadListOne = data;
    },
    stopProp(e) {
      e.stopPropagation();
    },
    clickheadMenu(type, data, index) {
      if (type == "oneAll") {
        this.$store.commit("PARENTID", null);
        if (sessionStorage.getItem("secondList")) {
          sessionStorage.removeItem("secondList");
        }
        this.allStatusOne = 1;
        this.allStatusTwo = 1;
        this.act = -1;
        this.active = -1;
        this.actived = -1;
        this.isTwo = false;
        this.$emit("subjectId", "", "all");
      } else if (type == "secAll") {
        //点击子级全部的 调取父级学科
        this.allStatusTwo = 1;
        this.active = -1;
        this.$store.commit("SETALL", 1);
        this.$store.commit("PARENTChiLD", null);
        this.$emit("subjectId", this.levelsubJectId || this.towLevelAllId);
      } else {
        //学科全部
        this.act = index;
        this.isTwo = true;
        this.reheadListTwo = this.getTreeData(data);
        this.active = -1;
        this.actived = -1;
        this.allStatusOne = "";
        this.allStatusTwo = 1;
        this.towLevelAllId = data.medicalId;
        this.$store.commit("PARENTID", index);
        this.$emit("subjectId", data.medicalId, "", data); //学科id给到searchList组件
        this.$emit("secondList", data.children); //children数组给到searchList
        this.$store.commit("PARENTChiLD", null);
        this.$store.commit("SETALL", 1);
      }
    },
    clickhead(type, data, index) {
      this.active = index;
      this.allStatusTwo = -1;
      this.$store.commit("PARENTChiLD", index);
      this.$store.commit("SETALL", null);
      this.$emit("subjectId", data.medicalId);
    },
    allSubType() {
      //所有分类
      this.subType = 1;
      this.subTypeInd = -1;
      this.$emit("subType", "", "all");
    },
    goSubType(type, data, ind) {
      //点击分类
      if (type == "oneItem") {
        this.subTypeInd = ind;
        this.subType = -1;
        this.$emit("subType", data.dictLabel);
      }
    },
    clickheadChil(type, data, index) {
      //折叠
      this.actived = index;
      sessionStorage.setItem("parentChilSonId", JSON.stringify(index));
      this.$emit("subjectId", data.medicalId);
    },
    getTreeData(data) {
      for (var i = 0; i < data.children.length; i++) {
        if (data.children[i].length < 1) {
          data.children[i].children = undefined;
        } else {
          this.getTreeData(data.children[i]);
        }
      }
      return data.children;
    },
  },
};
</script>
<style lang="scss" scoped>
$color: var(--colorBlue);
.hh_classify_wrap {
  width: 100%;
  background: #fff;
  padding-top: 10px;
  .hh_sub {
    display: flex;
    .gl_sub_t {
      padding-top: 5px;
      width: 76px;
      white-space: nowrap;
    }
    .hh_classify {
      flex: 1;
      .hh_classify_item {
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 6px 11px;
        border-radius: 7px;
        color: #333;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
      }

      .hh_classify_active {
        background: rgba(212, 229, 255, 1);
        color: $color;
        font-weight: 600;
      }
      .yh_classify_subTypeInd {
        background: rgba(212, 229, 255, 1);
        color: $color;
        font-weight: 600;
      }
    }
    .hh_twoSub_wrap {
      margin-bottom: 15px;
      width: 1140px;
      border-radius: 8px;
      background: #f7f7f7;
      .hh_pad {
        padding: 10px;
        .hh_item {
          display: inline-block;
          margin: 0 2px 2px 0;
          padding: 6px 11px;
          border-radius: 7px;
          color: #333;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;
        }
        .hh_classify_active,
        .yh_classify_active,
        .yh_classify_actived {
          background: rgba(212, 229, 255, 1);
          color: $color;
          font-weight: 600;
        }
      }
      .el-collapse {
        border: none;
      }
      ::v-deep .el-collapse-item__header {
        border: none;
        background: none;
        line-height: 1.5;
      }
      ::v-deep .el-collapse-item__content {
        padding-bottom: 0;
        border: none;
        background: #f7f7f7;
      }
      ::v-deep .el-collapse-item__wrap {
        border: none;
      }
    }
  }
  .hh_line {
    margin-bottom: 10px;
    border-color: rgba(230, 230, 230, 0.2);
  }
}
</style>
