<template>
  <!-- 点播列表页 -->
  <div class="container">
    <category @subjectId="subjectId" @subType="subType"></category>
    <div class="hh_con_wrap">
      <div class="hh_con common_inner">
        <live-com
          :type="'dibbleType'"
          :dibbledata="dibbledata"
          @getVerCode="getVerCode"
        ></live-com>
        <none-data
          v-if="dibbledata.length <= 0"
          :searchType="searchType"
        ></none-data>
        <pagination
          class="pagination jumpBtn"
          v-if="totalCount > 0"
          :pageSize="dibbleData.pageSize"
          :total="totalCount"
          @pageChange="pageChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <courseVerificationCode :courseId="courseId" />
  </div>
</template>
<script>
import API from "@/apis/studentsEndAPI/liveListAPI";
import Pagination from "@comps/backStageComponent/pagination";
import category from "../live/components/category";
import liveCom from "@/components/studentsComponent/liveCom";
import courseVerificationCode from "@/components/backStageComponent/courseVerificationCode";
import { EventBus } from "../../../assets/js/eventBus";
import noneData from "@comps/backStageComponent/noneData";
import CommonUtils from "@/utils";
export default {
  name: "dibbleList",
  components: {
    category,
    liveCom,
    Pagination,
    noneData,
    courseVerificationCode,
  },
  data() {
    return {
      courseId: "",
      searchType: 1,
      dibbleType: 2,
      totalCount: 0,
      dibbledata: [],
      dibbleData: {
        courseType: 2, //课程类型
        page: 1,
        pageSize: 20,
        createStartTime: "",
        createEndTime: "",
        state: "",
        subjectId: "", //学科id
        keyword: "",
        labels: "",
      },
    };
  },
  watch: {
    '$store.state.student.value': {
      handler(val) {
        if (val == '') {
          this.dibbleData.keyword = ''
          this.dibbleInit()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if(this.$store.state.student.value) {
      this.dibbleData.keyword = this.$store.state.student.value
      this.dibbleInit()
    }
    EventBus.$emit("curIndex", 2);
    if (sessionStorage.getItem("dibbledata")) {
      let data = JSON.parse(sessionStorage.getItem("dibbledata"));
      if (data.dataList.length > 0) {
        this.totalCount = data.rowCount;
        this.dibbledata = data.dataList;
      }
    } else if (sessionStorage.getItem("nodata")) {
      this.dibbledata = [];
    } else {
      this.dibbleInit();
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem("secondList");
    sessionStorage.removeItem("livedata");
    sessionStorage.removeItem("nodata");
  },
  beforeRouteLeave(to, from, next) {
    if (from.path == "/dibbleList")
      sessionStorage.setItem("flag", JSON.stringify(true));
    if (to.path == "/home") sessionStorage.removeItem("flag");
    if (to.fullPath == "/liveList") {
      this.$EventBus.$emit("fromDibble", true);
    }
    next();
  },
  methods: {
    getVerCode(val) {
      this.courseId = val.courseId;
      this.$store.state.isVerifi = true;
    },
    async dibbleInit() {
      let res = await API.getLiveList(
        CommonUtils.parseToParams(this.dibbleData)
      );
      if (res && !res.status) {
        this.totalCount = res.rowCount;
        this.dibbledata = res.dataList;
      }
    },
    pageChange(val) {
      if (val) {
        this.dibbleData.page = val;
        this.dibbleInit();
      }
    },
    sizeChange(val) {
      if (val) {
        this.dibbleData.page = val;
        this.dibbleInit();
      }
    },
    subjectId(subjectId, type) {
      if (subjectId) {
        this.dibbleData.subjectId = subjectId;
        this.dibbleInit();
      } else if (type == "all") {
        this.dibbleData.subjectId = "";
        this.dibbleData.page = 1;
        this.dibbleInit();
      }
    },
    subType(subType, type) {
      if (subType) {
        this.dibbleData.labels = subType;
        this.dibbleInit();
      } else if (type == "all") {
        this.dibbleData.labels = "";
        this.dibbleInit();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/common.scss";
.container {
  min-height: calc(100vh - 303px);
  width: 100%;
  background: #f7faff;
  .hh_con_wrap {
    width: 100%;
    .hh_pag {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
    }
  }
}
</style>
