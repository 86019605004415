<template>
  <div class="pagination">
    <el-pagination
      @size-change="handleSize"
      @current-change="handlePage"
      :current-page.async="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      background
      :total="total"
    />
    <el-button class="jumpBtn" type="primary" size="small" @click="handlePage(page)">跳转</el-button>
  </div>
</template>
<script>
export default {
  name: 'pagination',
  props: ['total', 'pageSize'],
  data() {
    return {
      page: 1,
    }
  },
  mounted() {},
  methods: {
    handlePage(val) {
      this.page = val
      this.$emit('pageChange', val)
    },
    handleSize(val) {
      this.page = val
      this.$emit('sizeChange', val)
    },
  },
}
</script>
<style>
</style>
