import axios from "@plugins/http.js";
class dictionaryManagementAPI {
  newBuildDictionary(params) {
    // 新建字典
    return axios.post(`/dict-group`, params);
  }
  dictionaryList(params) {
    //字典列表
    return axios.get(`/dict-group-list?${params}`);
  }
  editDictionary(params) {
    //编辑字典
    return axios.put(`/dict-group`, params);
  }
  delDictionary(ids) {
    //删除字典
    return axios.delete(`/dict-group?ids=${ids}`);
  }
  getDictionaryInfo(id) {
    //获取字典详情
    return axios.get(`/dict-group/${id}`);
  }
  getDictionaryData() {
    //dictType 修改成news-type-list接口
    //获取资讯数据列表
    return axios.get(`/client/news-type-list`);
  }
  getDicData(dictType) {
    //获取字典数据列表
    return axios.get(`/dict-group/${dictType}/dict`);
  }
  addDictionaryData(params) {
    //新建字典数据
    return axios.post(`/dict`, params);
  }
  editDictionaryData(params) {
    //编辑字典数据
    return axios.put(`/dict`, params);
  }
  delDictionaryData(ids) {
    //删除字典数据
    return axios.delete(`/dict-list?ids=${ids}`);
  }
}

export default new dictionaryManagementAPI();
