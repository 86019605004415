import http from '@/plugins/http.js';
class liveMenuDetailApi { // 直播详情接口
  addEvalution(params) { // 添加评论
    return http.post(`/evaluation`, params)
  }
  getEvalution(params) { // 评论列表
    return http.get(`/evaluation-list?${params}`)
  }
  addCollect(userId, params) { // 用户添加收藏
    return http.post(`/user/${userId}/collect`, params)
  }
  delCollect(userId, params) { // 用户取消收藏
    return http.delete(`/user/${userId}/collect`, params)
  }
  collectCourseList(userId) { // 用户收藏课程列表
    return http.get(`/user/${userId}/collect/course-list`)
  }
  signRecord(params) { // 签到签退
    return http.post(`/sign-record`, params)
  }
  recordList(params) { // 暂时用不到 签到签退记录表
    return http.get(`/sign-record-list?${params}`)
  }
  course(id, code) { // 课程详情
    return http.get(`/client/course/${id}?code=${code}`)
  }
  getNoteList(userId, params) { // 笔记列表
    return http.get(`/user/${userId}/note-list?${params}`)
  }
  getAddNote(userId, params) { // 添加笔记
    return http.post(`/user/${userId}/note`, params)
  }
  getDeleteNote(userId, noteId) { // 添加笔记
    return http.delete(`/user/${userId}/note/${noteId}`)
  }
  getUserList(userId) { // 聊天获取用户信息
    return http.get(`/client/user-list?userIds=${userId}`)
  }
  getVideoMenuList(courseId, menuId, params) { // 获取视频菜单列表
    return http.get(`/course/${courseId}/menu/${menuId}/file-list?${params}`)
  }
  getStudyLog(url, params, headers) { // 学习课程进度上报
    return http.post(url, params, headers)
  }
  saveCourseZan(params) {//学员端——直播课点赞数保存
    return http.post(`/client/course/zan`,params)
  }
  getCourseZan(id) {//学员端直播课点赞数查询
    return http.get(`/client/course/zan/${id}`)
  }
  visitorStudyLog(url,params,headers) {
    //游客访问直播学习上报
    return http.post(url,params,headers)
  }
  
  
}
export default new liveMenuDetailApi()
