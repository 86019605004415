<template>
  <div class="hh_video_wrap">
    <div class="hh_video" v-for="(item, index) in datas" :key="index" @click="getDetail(item)">
<!--      <p v-if="type == 'liveType'" :style="getStyle(item.liveInfo.liveState)" class="hh_stauts hh_com">-->
<!--        {{item.liveInfo.liveState | getStatus(item.liveInfo.liveState)}}-->
<!--      </p>-->
      <!-- <p v-if="type == 'liveType'" class="hh_num hh_com">
        <img src="@/assets/img/studentImg/liveDetail/audience-icon.png" /> {{item.viewCount}}
      </p> -->
      <img class="pageImg" :src="item.coverUrl " alt="" />
      <p class="hh_detail_title">{{item.courseTitle}}</p>
      <p v-if="type == 'liveType'" class="hh_time">直播时间: {{item.liveInfo.liveStartTime}}&nbsp;&nbsp;{{getDay(item.liveInfo.liveStartTime)}}</p>
      <div v-if="type == 'dibbleType'" class="hh_time">
        <!-- <p>01:59:59</p>  -->
        <p class="yh_join"><span>{{item.viewCount}}人参与</span></p>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/apis/backStageEndAPI/coursewareManagementAPI'
import { getDay, getStatus } from '@/assets/js/format.js'
import CommonUtils from '@/utils/index.js'

export default {
  name: 'liveCom',
  props: ['type', 'livedata', 'dibbledata'],
  filters: {
    getStatus,
  },
  data() {
    return {
      datas: [],
      query: {
        id: '',
        code: '',
      },
    }
  },
  computed: {
    getStyle() {
      return style => {
        if (style == 0) {
          return { background: '#F65160' }
        } else if (style == 1) {
          return { background: '#00D097' }
        } else if (style == 2) {
          return { background: '#FDC748' }
        } else if (style == 3) {
          return { background: '#FDC748' }
        }
      }
    },
  },
  watch: {
    livedata(val) {
      if (val) {
        this.datas = val
      }
    },
    dibbledata(val) {
      if (val) {
        this.datas = val
      }
    },
  },
  methods: {
    getDay,
    navigator(type, item) {
      if (type == 'liveType') {
        this.$router.push({
          path: '/liveDetail',
          query: item,
        })
      } else {
        this.$router.push({
          path: '/dibbleDetail',
          query: item,
        })
      }
    },
    getDetail(item) {
      this.query.id = item.courseId
      if (item.courseLimits === 0) {
        this.navigator(this.type, this.query)
      } else if (item.courseLimits === 1) {
        if (!sessionStorage.getItem('educationToken')) {
          this.$store.state.isLogin = true
        } else {
          this.navigator(this.type, this.query)
        }
      } else if (item.courseLimits === 2) {
        this.$emit('getVerCode',item)
      }
    },
  },
}
</script>
<style lang='scss' scoped>
@import 'src/assets/css/studentsEndCSS/home';
.hh_time {
  padding-top: 15px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #91929e;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  .yh_join{
    width: 100%;
    text-align: right;
  }
}
.pageImg {
  height: 166px;
  max-width: 100%;
  border-radius: 12px;
}
</style>
