import moment from 'moment';
export const getTime = (stamp) => {
  if (stamp) {
    return moment(stamp).format('YYYY-MM-DD hh:mm:ss');
  }
}
export const getDate = (stamp) => {
  if (stamp) {
    return moment(stamp).format('YYYY-MM-DD');
  }
}
export const getDay = (day) => {
  if (day != null) {
    let week = moment(day).format('d')
    switch (week) {
      case '1':
        return '周一'
      case '2':
        return '周二'
      case '3':
        return '周三'
      case '4':
        return '周四'
      case '5':
        return '周五'
      case '6':
        return '周六'
      case '0':
        return '周日'
    }
  }
}
export const getStatus = (key) => {
  let status = ''
  switch (key) {
    case 0 :
      status = '即将开始'
      break;
    case 1:
      status = '直播中'
      break;
    case 2:
      status = '已结束'
      break
    case 3:
      status = '回放'
      break
  }
  return status
}
export const formatTime = (seconds) => {
  let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
  seconds -= 3600 * hour;
  let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
  seconds -= 60 * min;
  let sec = seconds >= 10 ? seconds : '0' + seconds;
  return hour + ':' + min + ':' + sec;
}
