import axios from '@/plugins/http.js';
class liveListApi {
  getLiveList(params) {//获取直播列表
    return axios.get(`/client/course-list?${params}`);
  }
  getMedicalList(params) {
    return axios.get(`/medical-list?status=${params}`);
  }
  bannerlist(params) {//学员端——轮播列表
    return axios.get(`client/banner-list?showArea=${params}`)
  }
  
} 
export default new liveListApi()