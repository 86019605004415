<template>
  <!--课程验证码-->
  <div>
    <el-dialog
      class="dialog_wrapper rgba_verifi"
      v-if="$store.state.isVerifi"
      :visible.sync="$store.state.isVerifi"
      :modal-append-to-body="false"
      :title="'请输入观看邀请码'"
      @close="close"
      top="35vh"
      width="280px"
    >
      <el-form class="rgba_form">
        <el-form-item>
          <el-input v-model="verifiCode" placeholder="验证码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn btn_blue rgba_register" @click="confirmCode">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import liveMenuDetailAPI from "@/apis/studentsEndAPI/liveMenuDetail";
import { EventBus } from '@/assets/js/eventBus'
export default {
  name: "courseVerificationCode",
  props: ['courseId', 'type','path'],
  data() {
    return {
      verifiCode: '',
      currentCode: '',
      id: '',
    }
  },
  mounted() {
    EventBus.$on('courseId', val => {
      if(val) {
        this.id = val
        console.log(this.id)
      }
    })
  },
  methods: {
    close() {
      this.verifiCode = ''
    },
    confirmCode() {
      // 验证验证码
      this.currentCode = this.verifiCode
      liveMenuDetailAPI.course(this.courseId ? this.courseId : this.id, this.currentCode).then(res => {
        if (res && !res.status) {
          this.$store.state.verifiCode = this.verifiCode
          this.verifiCode = ''
          this.$store.commit('updateVerifi', false)
          if (res.courseType == 1) {
            if (this.path) {
              this.$router.push({
                path: this.path,
                query: {
                  id: res.courseId,
                  code: this.currentCode
                }
              })
            } else {
              this.$router.push({
                path: '/liveDetail',
                query: {
                  id: res.courseId,
                  code: this.currentCode
                }
              })
            }

          } else if (res.courseType == 2) {
            if (this.path) {
              this.$router.push({
                path: this.path,
                query: {
                  id: res.courseId,
                  code: this.currentCode
                }
              })
            } else {
              this.$router.push({
                path: '/dibbleDetail',
                query: {
                  id: res.courseId,
                  code: this.currentCode
                }
              })
            }

          }
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.rgba_verifi {
	::v-deep .el-dialog__header {
		text-align: center;
	}
	::v-deep .el-dialog__body {
		padding-bottom: 2px !important;
	}
	.rgba_form {
		/deep/ .el-form-item__content {
			text-align: center;
		}
		.rgba_form {
			width: 100%;
			font-weight: normal;
			font-size: 16px;
			line-height: 40px;
		}
	}
}
</style>
